<template>
  <div class="home">
    <!-- 错误！请重新登录.. -->
    <!-- <van-field v-model="from.username" label="手机号" />
    <van-field v-model="from.password" label="密码" />
    <van-button type="primary" @click="testData">登录</van-button>
    <van-field readonly clickable label="省市区" :value="districtName" placeholder="选择省市区" @click="showPicker = true" />

    <van-popup v-model="showPicker" position="bottom">
      <van-cascader
        v-model="value"
        title="请选择所在地区"
        :options="columns"
        :field-names="fieldNames"
        @close="closePick"
        @finish="finishPick"
      />
    </van-popup>
    <van-uploader :after-read="afterRead" /> -->
    <!-- <MyscrollList ref="myList01" :loading="loading" :list="list" @myload="myload">
      <template #default="slotProps">
        <van-cell :title="slotProps.item" :value="slotProps.item" />
      </template>
    </MyscrollList> -->
    <!-- 最多显示一行 -->
    <!-- <div class="van-ellipsis">这是一段最多显示一行的文字，多余的内容会被省略</div> -->

    <!-- 最多显示两行 -->
    <!-- <div class="van-multi-ellipsis--l2">
      这是一段最多显示两行的文字，多余的内容会被省略
    </div> -->

    <!-- 最多显示三行 -->
    <!-- <div class="van-multi-ellipsis--l3">
      这是一段最多显示三行的文字，多余的内容会被省略
    </div> -->
  </div>
</template>

<script>
import { districts } from '@/api/public'
import { upProToOss } from '@/utils/ali-oss'
// 组件使用
// import MyscrollList from '../components/MyscrollList'
export default {
  name: 'Home',
  // components: { MyscrollList },
  data() {
    return {
      from: {},
      showPicker: false,
      value: '',
      districtName: '',
      columns: [],
      fileList: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      list: [],
      loading: false
    }
  },
  created() {
    // this.$router.replace('/home/index')
  },
  methods: {
    myload() {
      this.$refs.myList01.load_loading(true)
      console.log('父组件load')
      this.$utils.debounce(() => {
        for (let i = 0; i < 20; i++) {
          this.list.push(this.list.length + 1)
        }
        if (this.list.length >= 40) {
          // 加载状态结束
          this.$refs.myList01.load_loading(false)
          // 数据全部加载完成
          this.$refs.myList01.load_finished(true)
        }
      }, 100)()
    },
    // 加载数据
    testData() {
      districts().then(res => {
        this.columns = res.data
      })
      // const from = {
      //   username: this.from.username,
      //   password: this.from.password
      // }
      // this.$store.dispatch('user/login', from)
      //   .then(() => {
      //   })
      //   .catch(() => {
      //     this.loading = false
      //   })
    },
    // 省市区
    finishPick(value) {
      this.showPicker = false
      this.districtName = value.selectedOptions[0].label + '-' + value.selectedOptions[1].label + '-' + value.selectedOptions[2].label
    },
    closePick() {
      this.showPicker = false
    },
    // 图片上传
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      file.status = 'uploading'
      file.message = '上传中...'
      setTimeout(() => {
        file.status = 'failed'
        file.message = '上传失败'
      }, 1000)
      console.log(file)
      upProToOss(file).then(res => {
        this.fileList.push(res)
        console.log('this.fileList', this.fileList)
      })
    }
  }
}
</script>
